<template>
  <div class="cmp-bpmn-instance-diagram">
    <!-- 弹窗 -->
    <!-- 弹窗 -->
    <van-popup class="bpmn-dialog-process"
               style="width:96%;padding:4px"
               :closeable="true"
               :close-on-popstate="true"
               v-model="diagramDialogVisible">
      <div>
        <h3>业务审核流程进度</h3>
        <div style="width:100%;height:300px">
          <div ref="canvas"
               id="canvas"
               style="height:100%;background-color:#efefef;"></div>
        </div>
        <div class="button-footer">
          <van-button type="info"
                      @click="resetZoom">重置位置</van-button>
          <van-button plain
                      type="info"
                      @click="diagramDialogVisible = false">返 回</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { SUCCESS_CODE } from "@/apis/notification.js";

import Viewer from "bpmn-js/lib/NavigatedViewer";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import { sortBy, pick, assign } from "lodash-es";

export default {
  data() {
    return {
      diagramDialogVisible: false,
      bpmnViewer: null,
      bpmnInfo: {
        name: "",
        childActivityInstances: "",
        activityName: "",
        xml: "",
        state: "",
        processDefinitionId: "",
        processInstanceId: ""
      }
    };
  },
  methods: {
    async viewDetail(row) {
      row.processInstanceId = row.rootProcessInstanceId;
      assign(this.bpmnInfo, pick(row, "state", "processDefinitionId", "processInstanceId"));

      if (row.state == "ACTIVE") {
        await this.getBpmnProcessActiveInstance(row.processInstanceId);
      }
      await this.getBpmnDiagram(this.bpmnInfo.processDefinitionId);

      this.diagramDialogVisible = true;
      setTimeout(() => {
        this.openDiagram();
      }, 0);
    },
    async getBpmnDiagram(id) {
      let ret = await this.$api.getBpmnDiagram({
        placeholder: { id }
      });
      if (ret.code != SUCCESS_CODE) return;
      this.bpmnInfo.xml = ret.data.bpmn20Xml;
    },
    async openDiagram() {
      // import diagram
      try {
        const container = this.$refs["canvas"];
        container.innerHTML = "";

        if (!container) return;

        this.bpmnViewer = new Viewer({
          container,
          keyboard: {
            bindTo: document
          }
        });

        await this.bpmnViewer.importXML(this.bpmnInfo.xml);
        // const elementRegistry = this.bpmnViewer.get("elementRegistry");
        // const graphicsFactory = this.bpmnViewer.get("graphicsFactory");
        if (this.bpmnInfo.state == "ACTIVE") {
          var targetElementId = this.bpmnInfo.childActivityInstances[0].activityId;
          var canvas = this.bpmnViewer.get("canvas");
          canvas.resized();
          canvas.zoom("fit-viewport", "auto");
          canvas.addMarker(targetElementId, "highlight");
          console.log("hightlight marker");
        } else {
          this.setMarker();
        }
      } catch (err) {
        console.error("could not import BPMN 2.0 diagram", err);
      }

      // this.resetZoom();
    },
    resetZoom() {
      var canvas = this.bpmnViewer.get("canvas");
      canvas.resized();
      canvas.zoom("fit-viewport", "auto");
    },
    setMarker() {
      // access viewer components
      var canvas = this.bpmnViewer.get("canvas");
      var overlays = this.bpmnViewer.get("overlays");

      // zoom to fit full viewport

      canvas.resized();
      canvas.zoom("fit-viewport", "auto");
      // attach an overlay to a node
      // overlays.add("SCAN_OK", "note", {
      //   position: {
      //     bottom: 0,
      //     right: 0
      //   },
      //   html: '<div class="diagram-note">流程已完成</div>'
      // });
      // canvas.addMarker("SCAN_OK", "needs-discussion");
    },
    async getBpmnProcessActiveInstance(id) {
      let ret = await this.$api.getBpmnProcessActiveInstance({ placeholder: { id } });
      if (ret.code != SUCCESS_CODE) return;
      assign(this.bpmnInfo, pick(ret.data, ["name", "childActivityInstances", "activityName", "processDefinitionId"]));
    }
  }
};
</script>
<style lang="less">
.highlight .djs-visual > :nth-child(1) {
  stroke: green !important;
  stroke-width: 2px;
  fill: rgba(255, 255, 0, 0.95) !important;
}

.bpmn-dialog-process {
  .button-footer {
    padding: 0.25rem;
    text-align: center;
    .van-button + .van-button {
      margin-left: 0.5rem;
    }
  }
}
</style>
